import Logo from 'assets/dani_512_512.png';
export function Servicos() {
  return (
    <article className="bg-white">
      <img src={Logo} alt="logo" />
      <h1>SERVIÇOS</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquid doloribus aperiam hic,
        unde accusantium illum fuga, laudantium a nemo quo aliquam magnam dolorem maxime veniam enim
        quis delectus perspiciatis sapiente.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquid doloribus aperiam hic,
        unde accusantium illum fuga, laudantium a nemo quo aliquam magnam dolorem maxime veniam enim
        quis delectus perspiciatis sapiente.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquid doloribus aperiam hic,
        unde accusantium illum fuga, laudantium a nemo quo aliquam magnam dolorem maxime veniam enim
        quis delectus perspiciatis sapiente.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquid doloribus aperiam hic,
        unde accusantium illum fuga, laudantium a nemo quo aliquam magnam dolorem maxime veniam enim
        quis delectus perspiciatis sapiente.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquid doloribus aperiam hic,
        unde accusantium illum fuga, laudantium a nemo quo aliquam magnam dolorem maxime veniam enim
        quis delectus perspiciatis sapiente.
      </p>
    </article>
  );
}
